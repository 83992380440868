/* ###### 4.4 Forms   ###### */

/* Checkbox */
.ckbox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 1;
  margin-bottom: 0;

  span {
    padding-left: 15px;

    &:empty {
      float: left;
    }

    &:before,
    &:after {
      line-height: 18px;
      position: absolute;
    }

    &:before {
      content: "";
      width: $ckbox-size;
      height: $ckbox-size;
      background-color: #fff;
      border: 1px solid $gray-500;
      top: 1px;
      left: 0;
    }

    &:after {
      top: 1px;
      left: 0;
      width: $ckbox-size;
      height: $ckbox-size;
      content: "";
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
      background-size: 65%;
      background-repeat: no-repeat;
      background-position: 55% 45%;
      background-color: theme-color("primary");
      line-height: 17px;
      display: none;
    }

    &:empty {
      padding-left: 0;
      width: 3px;
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    margin: 0;

    &:checked {
      + span:after {
        display: block;
      }
    }

    &[disabled] {
      + span,
      + span:before,
      + span:after {
        opacity: 0.75;
      }
    }
  }
}

.ckbox-inline {
  display: inline-block;
}

/* Radio Box */
.rdiobox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 18px;

  span {
    padding-left: 13px;

    &:before,
    &:after {
      line-height: 18px;
      position: absolute;
    }

    &:before {
      content: "";
      width: $rdiobox-size;
      height: $rdiobox-size;
      background-color: #fff;
      border: 1px solid $gray-500;
      border-radius: 50px;
      top: 2px;
      left: 0;
    }

    &:after {
      content: "";
      width: $rdiobox-size - 10px;
      height: $rdiobox-size - 10px;
      background-color: #fff;
      border-radius: 50px;
      top: 7px;
      left: 5px;
      display: none;
    }

    &:empty {
      padding-left: 0;
      width: 16px;
      display: block;
    }
  }

  input[type="radio"] {
    opacity: 0;
    margin: 0;

    &:checked {
      + span:before {
        border-color: transparent;
        background-color: theme-color("primary");
      }
      + span:after {
        display: block;
      }
    }

    &[disabled] {
      + span,
      + span:before,
      + span:after {
        opacity: 0.75;
      }
    }
  }
}

.rdiobox-inline {
  display: inline-block;
}

.form-group-rdiobox {
  display: flex;
  align-items: center;

  .rdiobox {
    margin-bottom: 0;

    + .rdiobox {
      margin-left: 30px;
    }

    span {
      padding-left: 0;
    }
  }
}

.az-toggle {
  width: 60px;
  height: 25px;
  background-color: $gray-400;
  padding: 2px;
  position: relative;
  overflow: hidden;

  &.disabled {
      opacity: .3;
  }

  span {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    display: block;
    width: 20px;
    background-color: #fff;
    cursor: pointer;
    @include transition($transition-base);

    &::before,
    &::after {
      position: absolute;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #fff;
      top: 2px;
      line-height: 1.38;
    }

    &::before {
      content: "on";
      left: -25px;
    }

    &::after {
      content: "off";
      right: -29px;
    }
  }

  &.on {
    background-color: $az-color-primary;

    span {
      left: 37px;
    }
  }
}

.az-toggle-secondary.on {
  background-color: $blue;
}
.az-toggle-success.on {
  background-color: $green;
}
.az-toggle-dark.on {
  background-color: $gray-900;
}

.az-toggle-warning.on {
  background-color: $amber;
}

.az-form-group {
  padding: 12px 15px;
  border: 1px solid $input-border-color;

  &.focus {
    border-color: $gray-400;
    box-shadow: 0 0 0 2px rgba($az-color-primary, 0.16);
  }

  .form-label {
    font-size: $font-size-sm;
    margin-bottom: 2px;
    font-weight: 400;
    color: $gray-500;
  }

  .form-control {
    padding: 0;
    border-width: 0;
    height: 25px;
    color: $gray-900;
    font-weight: 500;

    &:focus {
      box-shadow: none;
    }
  }
}

.input-label {
  color: $gray-700;
  font-size: $font-size-sm;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 1;
  margin-bottom: 10px;
  text-transform: uppercase;

  &.custom-control-label {
      line-height:unset;
  }
}

.input-helper {
  font-size: 12px;
  color: $gray-600;
}

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched,
.validate-children.ng-invalid input.ng-touched.validate-from-parent {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

input:not(.no-validate).ng-valid.ng-touched,
textarea:not(.no-validate).ng-valid.ng-touched {
  border-color: #3bb001;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233bb001' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

textarea.ng-valid.ng-touched,
textarea.ng-invalid.ng-touched {
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.input-error {
  color: #dc3545;
  list-style: none;
  font-size: 12px;
  padding: 0;
  margin: 5px 0 0;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e3e7ed;
}

.angular-editor-textarea {
  min-height: calc(100vh - 200px) !important;
  overflow-x: auto !important;
  margin-top: 0 !important;
}
