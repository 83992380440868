/* ###### 4.1 Accordion ###### */

.az-accordion {
  .accordion-item {
    background-color: $gray-100;
    border: 1px solid $az-border-base;
    position: relative;
    @include transition($transition-base);

    @include hover-focus() {
      cursor: pointer;
      background-color: darken($gray-100, 1%);
      border-color: darken($az-border-base, 10%);
      z-index: 4;
    }

    &.active {
      background-color: #fff;
      z-index: 5;
      border-color: darken($az-border-base, 10%);

      .accordion-title {
        color: $blue;
      }
      .accordion-body {
        display: block;
      }
    }

    + .accordion-item {
      margin-top: -1px;
    }
  }

  .accordion-header {
    display: flex;
    align-items: center;
    padding: 15px 20px;
  }

  .accordion-title {
    font-weight: 700;
    color: $body-color;
    margin: 0;
    @include transition($transition-base);
  }

  .accordion-body {
    display: none;
    padding: 0 20px 20px;
  }
}

.accordion {
  background-color: #f4f5f8;
  color: #3b4863;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}

.accordionp:after {
  content: "\02795";
  font-size: 13px;
  float: right;
  margin-left: 5px;
}

.accordionp-active:after {
  content: "\02796";
  font-size: 13px;
  float: right;
  margin-left: 5px;
}

.accordion-button {
  border: none;
  background: $gray-100;
  font-weight: 800;
  line-height: 1;
  margin-top: 4px;
  padding: 18px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;

  &::after {
    content: "\02796";
    font-size: 13px;
    float: right;
    margin-left: 5px;
  }

  &.collapsed::after {
    content: "\02795";
    font-size: 13px;
    float: right;
    margin-left: 5px;
  }
}

.accordion-body {
    border: 4px solid $gray-100;
    padding: 18px;
}
